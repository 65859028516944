import { Cmp, state } from ":mods";
import { AMaterialLesson, EditMaterialViewProps } from "../../models";
import { For, Match, Show, Switch, batch, createEffect, createRenderEffect, splitProps } from "solid-js";
import { materialContentLessonTemplateDetails } from "../../mocks/material-lesson";
import { getMaterialType, getModuleRoute, getStageRoute } from "../../methods";
import { getMaterialDetails, updateMaterial } from "../../apis";
import { useParams } from "@solidjs/router";
import { ShowMaterialLesson } from "../show-material/lesson";
import { EditMaterialLesson } from "./lesson";

export function EditMaterial(props: EditMaterialViewProps) {
  const [local, others] = splitProps(props, ["$", "materialID"]);
  const params = useParams();
  const materialId = local.materialID ?? params.id;
  const $details = state.createAsync([getMaterialDetails, materialId]);
  const type = $details.derive((s) => getMaterialType(s.data));
  const Layer = local.$.actions.createLayer({
    backMsg: "Back To Submodule",
    events: {
      backTo: () => {
        if ($details.state.success) {
          const details = $details.unwrap.value;
          console.log("details :: ", details);
          return getModuleRoute(details.data.module_id); // your route
        }
        return undefined;
      },
    },
  });

  // $details.on((event, { value }) => {
  //   // console.log("reached :: ", value);

  //   if (event === "cleared") {
  //     return;
  //   }
  //   const data = $details.value.data;
  //   // layer.setBreadcrumb([
  //   //   {
  //   //     title: "edit stage",
  //   //     to: getStageRoute(),
  //   //   },
  //   //   // {
  //   //   //   title: "edit stage",
  //   //   //   to: getStageRoute(),
  //   //   // },
  //   // ]);
  // });

  // createEffect(() => {
  //   console.log("type of material is :: ", type());
  // });

  function onUpdate(content: AMaterialLesson) {
    // console.log("onUpdate :: ", content);
    const data = { ...$details.value.data, content };
    updateMaterial(materialId, data)
      .then(() => {
        local.$.actions.pushToast({
          type: "success",
          message: "Material Updated",
        });
      })
      .catch(() => {
        local.$.actions.pushToast({
          type: "error",
          message: "An error occured while trying to update material, please contact support!",
        });
      });
  }
  return (
    <Switch fallback={<div>unknown resource state</div>}>
      <Match when={$details.state.loading}>Loading...</Match>
      <Match when={$details.state.failed}>An error occured while fetching stage data {$details.error} ...</Match>
      <Match when={$details.state.success}>
        <Layer>
          <Switch fallback={<div>unknown material content type</div>}>
            {/* lesson */}
            <Match when={type() === "lesson"}>
              <EditMaterialLesson $={local.$} content={$details.value.data.content as any} onUpdate={onUpdate} />
            </Match>
            {/* question */}
            <Match when={type() === "question"}>questions are not editable at the moment</Match>
            {/* task */}
            <Match when={type() === "task"}>tasks are not editable at the moment</Match>
          </Switch>
        </Layer>
      </Match>
    </Switch>
  );
}
